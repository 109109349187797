.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #101824 0%,
      rgba(29, 49, 79, 0) 100%
    );
}


.loaderContainer .loaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.loaderContainer .loaderWrapper .text {
    position: relative;
    color: #ffffff;
    font-family: "Inter";
    font-size: 32px;
    clip-path: inset(0 1ch 0 0);
    animation: textLoading 1s steps(4) infinite;
margin-bottom: 16px;
}

@keyframes textLoading {
    to {
        clip-path: inset(0 -1ch 0 0);
    }
}

.loaderContainer .loaderWrapper .loadingBar {
    position: relative;
    width: 200px;
    height: 12px;
    border: 1px solid rgba(171, 136, 72, 0.58);
    border-radius: 9px;
    padding: 2px;
}

.loaderContainer .loadingBar .loadingbarfillBg {
    background: linear-gradient(0deg, #858dd615, #858dd615);
    width: 100%;
    height: 100%;
    opacity: 1;
    border-radius: 20px;
    display: flex;
    justify-content: left;
}

.loaderContainer .loadingBar .loadingbarfillBg .loadingImage {
    max-width: 5.5%; 
    height: 12px;
}