.unityWrapperBG {
  background: #131313;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  display: flex;
  justify-content: center;
  align-items: center; 
}

.pendingBtn {
  position: absolute;
  background-color: #383838; /* Серый цвет кнопки */
  color: #fff; /* Белый цвет текста кнопки */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: not-allowed; /* Курсор неактивный, когда кнопка не кликабельна */
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  padding-right: 10px;
}