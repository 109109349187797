body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif; 
  overflow: hidden;
  justify-content: center;
}

@import url("https://fonts.googleapis.com/css?family=Inter");
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color:#131313; 
}